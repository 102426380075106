html
  font-family: $basic
  font-weight: normal
  font-size: 1rem
  font-display: auto

body
  width: 100%
  max-width: 100% !important
  color: $dark
  font-size: 95%
  font-family: $basic
  font-weight: 400
  font-smooth: always
  letter-spacing: .03em
  line-height: 1.6
  -webkit-font-smoothing: antialised
  -moz-osx-font-smoothing: grayscale
  overflow-x: hidden
  background-color: $body

a, * a
  color: inherit !important
  text-decoration: none !important

footer
  display: flex
  justify-content: center
  align-items: flex-end
  width: 100%
  +v-sm
    display: block
    padding: 20px
    height: auto
    min-height: auto !important
    text-align: center !important
  img
    transition: all ease-out .3s
    &:hover
      opacity: .5

#faq
  .w-80
    +v-xs
      min-width: 100% !important

header
  +v-sm
    z-index: 9999999
  a
    color: $white !important
    font-weight: 400
    transition: all ease-out .3s
    &:hover
      color: darken($theme, 10%) !important
  +v-sm
    position: fixed
    top: -20px
    left: 0
    width: 100%
    height: 80px
    max-height: 80px !important
    padding: 0 24px !important
    margin: 0 !important
    border-bottom: 1px solid $light
    background-color: $white
  #logo
    transform: translate(0,4px)
    +v-sm
      transform: translate(-6px,14px)
    +v-xs
      max-width: 120px
  .menu
    display: flex
    justify-content: flex-end
    align-items: center
    &.active
      background-color: transparent !important
    +v-sm
      position: fixed
      display: block
      top: 76px
      left: 0
      margin: 0 !important
      padding: 0 !important
      width: 100%
      height: 100vh
      min-height: 100vh
      overflow: hidden
      background-color: #fff
      z-index: 999 !important
      overflow-x: hidden
      overflow-x: hidden
      transition: left ease-out .5s
      &.active
        left: 120%
      a
        display: flex
        align-items: center
        width: 100%
        height: 60px
        padding: 0 24px !important
        border-top: 1px solid $light
        transform: translateX(-10px)
        &.social
          background-color: $light
          transform: translateX(-16px)

.burger
  display: none
  +v-sm
    position: fixed
    top: 30px
    right: 50px
    display: block
    span
      width: 24px
      height: 2px
      background-color: $medium
      transition: all ease-out .25s
      &.tt
        position: absolute
        left: 0
        top: 0
      &.mm
        position: absolute
        left: 0
        top: 8px
      &.bb
        position: absolute
        left: 0
        top: 16px
    &.open
      span
        width: 24px
        height: 2px
        background-color: $medium
        &.tt
          position: absolute
          left: 0
          top: 8px
          transform: rotate(45deg)
        &.mm
          width: 0
          position: absolute
          left: 12px
          top: 16px
          background-color: transparent
        &.bb
          position: absolute
          left: 0
          top: 8px
          transform: rotate(-45deg)
  +v-xs
    position: fixed
    top: 28px
    right: 56px
    display: block

.mobile
  +v-sm
    transform: translate(-4px,11px)
  .in
    +v-sm
      transform: translateY(1px)

.flex
  &.m-block
    +v-sm
      display: block
      margin: 0 !important
  &.sign
    img
      +v-sm
        display: inline-block
        margin: 4px !important

.m-none
  +v-sm
    display: none
.m-icon
  +v-sm
    height: 100px !important
    margin-bottom: 16px
    padding: 0
  +v-xs
    width: 48px !important
    height: 72px !important
    margin-bottom: 16px
    padding: 0
.m-30
  +v-sm
    margin-top: 30px
.m-pad
  +v-sm
    padding: 12px 24px !important
    margin-bottom: 30px
.m-bottom
  +v-sm
    padding-bottom: 20px !important
.m-40
  +v-sm
    padding-top: 40px !important

.accordion
  border-radius: 24px !important
  .accordion-item
    *
      background-color: transparent !important
      border-left: none !important
      border-right: none !important
  .accordion-button
    padding: 24px 32px
    font-size: .55em
    font-weight: 500
    color: $dark !important
    background-color: transparent !important
  .accordion-body
    padding: 24px 32px
    font-size: 1.2em
    font-weight: 400
    color: $dark !important

/* animations.css */
@keyframes flipInOut
  0%, 30%, 100%
    opacity: 0
    visibility: hidden
    transform: rotateX(90deg)
  10%, 20%
    opacity: 1
    visibility: visible
    transform: rotateX(0deg)

.item
  opacity: 0
  position: absolute
  top: 53vh
  left: 35%
  right: auto
  transform: translate(-50%, -50%)
  animation: flipInOut 9s infinite

.item-1
  animation-delay: 0s

.item-2
  animation-delay: 3s

.item-3
  animation-delay: 6s

//

@keyframes move
  100%
    transform: translate3d(0, 0, 1px) rotate(360deg)

.background
  position: absolute
  width: 100vw
  height: 100vh
  top: 0
  left: 0
  //background: #1E2E59
  //background: #D89E0A/* Rectangle 13 */
  background: linear-gradient(108.77deg, #B11B63 0.82%, #461279 104.51%)
  overflow: hidden
  z-index: -1
  span
    width: 50vmin
    height: 50vmin
    border-radius: 50vmin
    backface-visibility: hidden
    position: absolute
    animation: move
    animation-duration: 45
    animation-timing-function: linear
    animation-iteration-count: infinite
    &:nth-child(0)
      color: #351244
      color: #FFE2BEcc
      top: 79%
      left: 4%
      animation-duration: 6s
      animation-delay: -38s
      transform-origin: -4vw -20vh
      box-shadow: -100vmin 0 12.806490205726604vmin currentColor
    &:nth-child(1)
      color: #051252
      color: #6268EAcc
      top: 38%
      left: 73%
      animation-duration: 20s
      animation-delay: -19s
      transform-origin: -5vw -9vh
      box-shadow: 100vmin 0 13.34047471167366vmin currentColor
    &:nth-child(2)
      color: #351244
      color: #D9A2D4cc
      top: 90%
      left: 56%
      animation-duration: 41s
      animation-delay: -26s
      transform-origin: 25vw -4vh
      box-shadow: 100vmin 0 12.972328416631793vmin currentColor
    &:nth-child(3)
      color: #051252
      color: #6268EAcc
      top: 29%
      left: 80%
      animation-duration: 14s
      animation-delay: -42s
      transform-origin: -6vw 21vh
      box-shadow: -100vmin 0 13.183159845102372vmin currentColor
    &:nth-child(4)
      color: #9DBA99
      color: #F1D093cc
      top: 85%
      left: 84%
      animation-duration: 49s
      animation-delay: -13s
      transform-origin: 17vw -17vh
      box-shadow: -100vmin 0 13.139425198764057vmin currentColor
    &:nth-child(5)
      color: #9DBA99
      color: #F1D093cc
      top: 91%
      left: 32%
      animation-duration: 19s
      animation-delay: -15s
      transform-origin: 4vw -5vh
      box-shadow: 100vmin 0 13.01017671217949vmin currentColor
    &:nth-child(6)
      color: #351244
      color: #D9A2D4cccc
      top: 58%
      left: 24%
      animation-duration: 17s
      animation-delay: -49s
      transform-origin: 7vw 17vh
      box-shadow: 100vmin 0 12.509684617619333vmin currentColor
    &:nth-child(7)
      color: #051252
      color: #6268EAcc
      top: 41%
      left: 78%
      animation-duration: 7s
      animation-delay: -9s
      transform-origin: -6vw 20vh
      box-shadow: -100vmin 0 13.265455305127514vmin currentColor
    &:nth-child(8)
      color: #051252
      color: #6268EAcc
      top: 13%
      left: 72%
      animation-duration: 26s
      animation-delay: -41s
      transform-origin: 24vw 19vh
      box-shadow: 100vmin 0 13.290758111629847vmin currentColor
    &:nth-child(9)
      color: #051252
      color: #6268EAcc
      top: 88%
      left: 23%
      animation-duration: 28s
      animation-delay: -45s
      transform-origin: 21vw 1vh
      box-shadow: -100vmin 0 13.275670847922717vmin currentColor
    &:nth-child(10)
      color: #351244
      color: #D9A2D4cc
      top: 30%
      left: 67%
      animation-duration: 22s
      animation-delay: -23s
      transform-origin: 0vw 5vh
      box-shadow: 100vmin 0 12.538072816448176vmin currentColor
    &:nth-child(11)
      color: #351244
      color: #D9A2D4cc
      top: 62%
      left: 6%
      animation-duration: 11s
      animation-delay: -5s
      transform-origin: 19vw 2vh
      box-shadow: 100vmin 0 12.702635695483973vmin currentColor
    &:nth-child(12)
      color: #351244
      color: #D9A2D4cc
      top: 40%
      left: 50%
      animation-duration: 40s
      animation-delay: -1s
      transform-origin: 17vw -17vh
      box-shadow: 100vmin 0 12.746617175103781vmin currentColor
    &:nth-child(13)
      color: #351244
      color: #D9A2D4cc
      top: 5%
      left: 17%
      animation-duration: 41s
      animation-delay: -12s
      transform-origin: -9vw -8vh
      box-shadow: 100vmin 0 13.092658112666099vmin currentColor
    &:nth-child(14)
      color: #051252
      color: #6268EAcc
      top: 17%
      left: 70%
      animation-duration: 6s
      animation-delay: -17s
      transform-origin: -19vw 24vh
      box-shadow: -100vmin 0 12.784132442920077vmin currentColor
    &:nth-child(15)
      color: #351244
      color: #D9A2D4cc
      top: 47%
      left: 41%
      animation-duration: 54s
      animation-delay: -17s
      transform-origin: -19vw 10vh
      box-shadow: 100vmin 0 13.225965272002862vmin currentColor
    &:nth-child(16)
      color: #351244
      color: #D9A2D4cc
      top: 26%
      left: 60%
      animation-duration: 36s
      animation-delay: -32s
      transform-origin: 23vw 14vh
      box-shadow: -100vmin 0 12.875513710327514vmin currentColor
    &:nth-child(17)
      color: #351244
      color: #D9A2D4cc
      top: 11%
      left: 64%
      animation-duration: 48s
      animation-delay: -29s
      transform-origin: -2vw -20vh
      box-shadow: -100vmin 0 12.665610945993997vmin currentColor
    &:nth-child(18)
      color: #351244
      color: #D9A2D4cc
      top: 78%
      left: 54%
      animation-duration: 27s
      animation-delay: -13s
      transform-origin: 15vw -24vh
      box-shadow: 100vmin 0 13.118561308291165vmin currentColor
    &:nth-child(19)
      color: #9DBA99
      color: #F1D093cc
      top: 3%
      left: 100%
      animation-duration: 9s
      animation-delay: -8s
      transform-origin: -22vw -1vh
      box-shadow: 100vmin 0 12.729761993715194vmin currentColor

#features
  border-raduis: 32px 32px 0 0 !important
  .sub-counter
    position: relative
    margin-top: -20px
    border-radius: 40px
    font-size: 1.2em
    font-weight: 600
    padding: 20px
    color: #777
    z-index: 1

.bg-1,
.bg-2,
.bg-3,
.bg-4
  height: 25vh
  margin-bottom: -1px
  border-left: none !important
  border-right: none !important
  transition: all ease-out 1s
  p
    padding-top: 10px !important
.bg-1
  &:hover
    background: $dark url('../../assets/jpg/bg1.jpg') center center no-repeat
    background-size: cover
    h3, p
      color: $white !important
.bg-2
  &:hover
    background: $dark url('../../assets/jpg/bg2.jpg') center center no-repeat
    background-size: cover
    h3, p
      color: $white !important
.bg-3
  &:hover
    background: $dark url('../../assets/jpg/bg3.jpg') center center no-repeat
    background-size: cover
    h3, p
      color: $white !important
.bg-4
  &:hover
    background: $dark url('../../assets/jpg/bg4.jpg') center center no-repeat
    background-size: cover
    h3, p
      color: $white !important

#winners
  margin-top: 100px
  background: $dark url('../../assets/jpg/bg28.jpg') center center no-repeat
  background-size: cover
  p
    font-size: 1em !important
    line-height: 1.35
    font-weight: 400

.winner
  &-1
    background-color: #D29217aa
    border-radius: 16px !important
  &-2
    background-color: #de479daa
    border-radius: 16px !important
  &-3
    background-color: #1E9DC6aa
    border-radius: 16px !important

.card
  position: relative
  min-height: 300px
  border: none
  &.analitics,
  &.start
    min-height: 280px
    .meta
      display: inline-flex
      justify-content: center
      align-items: center
      width: auto
      height: 40px
      padding: 0 30px 4px
      position: absolute
      bottom: 30px
      left: 48px
      border-radius: 20px
      border: 1px solid $grey
  &.start
    min-height: 300px

#program
  background-color: $lite

#programs-tabpane
  &-analitics
    .col-lg-4
      &:nth-child(3)
        .card
          border: none
          background: url(../../assets/png/b01.png)
          background-size: cover
          *
            display: none
      &:nth-child(7)
        .card
          border: none
          background: url(../../assets/png/b02.png) center center no-repeat
          background-size: cover
          *
            display: none
  &-start
    .col-lg-4
      &:nth-child(6)
        .card
          border: none
          background: url(../../assets/png/b03.png) center center no-repeat
          background-size: cover
          *
            display: none
#programs-tab
  &-analitics,
  &-start
    font-size: 1.25em
    font-weight: 500
    color: $dark
    border: none
    &.active
      color: $theme
      background-color: transparent
      border-bottom: 2px solid $theme
ul#programs
  border: none

.greyscale
  position: relative
  display: block
  opacity: .5
  filter: grayscale(100%)
  transition: all ease-out .3s
  z-index: 1000
  &:hover
    opacity: 1
    filter: grayscale(0)
    cursor: pointer

#cta
  .container
    //background: linear-gradient(287.16deg, #6268EA 18.1%, #373B84 116.34%)
    background: $dark url('../../assets/jpg/bg26.jpg') left center no-repeat
    background-size: 80%
    border-radius: 40px
    overflow: hidden
    padding: 0
    img
      width: 100%
      height: 100%
      object-fit: cover
