.title
  margin: 0
  padding: 0
  color: $dark
  font-size: 3em
  font-weight: 300
  +v-xs
    font-size: 1.2em
  &-mini
    font-size: 0.85em
    &.active
      color: $theme !important
  &-cta
    font-size: 2.75em !important
    font-weight: 400 !important
    line-height: 1.6 !important
    text-transform: uppercase
    +v-sm
      font-size: 1.65em
      font-weight: 400
      line-height: 1.4
    +v-xs
      font-size: 1.15em
      line-height: 1.25
  &-intro
    color: $dark
    font-size: 4.55em
    font-weight: 800
    font-family: $intro
    +v-sm
      zoom: .75
    +v-sm
      zoom: .35
    img
      transform: translateY(-24px)
      &:first-of-type
        transform: translateY(-8px)
  &-cta
    color: $white
    font-size: 3.55em
    font-weight: 500
    font-family: $intro
    line-height: 1.25
  &-mini
    font-size: 1.25em
    font-weight: 500
    color: $medium
  &-sub
    font-size: 1.1em
    font-weight: 500
    +v-xs
      font-size: .9em
      margin-left: 3%
      margin-right: 3%
    &.w-*
      +v-xs
        margin: 0 auto
        min-width: 96% !important

footer
  +v-sm
    margin-top: 80px
  a
    &:hover
      color: $dark !important
  .f
    &-text
      color: $footer
      font-size: 1em
      font-weight: 500
      line-height: 1.5

img
  +v-md
    max-width: 96% !important

.m-hide
  +v-md
    display: none !important

.m-tc
  +v-md
    text-align: center !important
