#top
  width: 100%
  height: 100vh

.canvas
  margin-left: 24px
  border-radius: 24px
  background-color: rgba(255,255,255,.25)
  border-radius: 40px
  img
    border: 2px solid $white
    border-radius: 40px
