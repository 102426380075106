.button
  display: inline-flex
  justify-content: center
  align-items: center
  height: 50px
  padding: 0 56px
  color: $white !important
  font-size: 1em
  font-weight: 400
  border-radius: 40px
  transition: all ease-out .25s
  background-color: $theme
  outline: none
  &:hover
    background-color: $button
  &.top
    height: 50px
    padding: 0 40px
    color: $white
    background-color: rgba(255, 255, 255, .15)
    &:hover
      color: $white !important
      background-color: $theme
